@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;

}


.top-bar
 {
    background-color:white !important;
    height: 80px;
}
.top-bar ul{
    background-color:transparent !important;
}

.top-bar {
    padding-right: 1.5rem ;
}

.menu-white-btn {
    color:#8F00FF!important;
}

.menu-white-btn:active {
    color: #FFD600;
}


.menu-white-btn:hover{
    color: #9829d89e !important;

}
a.demo {
    border-radius: 40px;
    padding: 10px;
    background-color: #FFD600;
    color: black !important;
}


.dropdown.menu>li.is-dropdown-submenu-parent>a::after {
    border-color: white transparent transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
    font-family: 'Poppins', sans-serif !important;
}

.hero-container {
    position: relative;
    top: 0;

}

.home-hero {
    width: 100vw;
    height: 100vh;
    /*background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/hero-1.png);*/
    background-image: url(../img/washokarma-bg1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    //padding-top: 50px;
}


.hero-sec-2 {
    padding: 0 0 0 80px;
    position: relative;
}

.mobile {
    position: absolute;
    top: -53px;
    left: 500px;
}

.hero-car-sec {
    width: 60%;
    height: 350px;
    background-image: url(../img/hero-2.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid white;
}

.home-hero h1 {
    color: white;
    font-weight: bold;
}

.hero-paragraph {

    color:white;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 33px;
}

.home-hero h1 {
    margin: 40px 0 10px;
}

.hero-content {
    margin: 0 20px 0 60px;
    text-align: left;
   // width: 40%;
}


.hero-content a {
    display: block;
    width: 154px;
    height: 50px;
    padding: 10px;
    background-color: #FFD600;
    color: black;
    border-radius: 50px;
    text-align: center;
    margin: 0 0 15px 0;

}

.hero-content a:hover {
    background-color: transparent;
    border: 1px solid #FFD600;
    color: white;
}

.hero-content img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.why-heading {
    text-align: center;
    margin: 50px 0 35px;
}

.why-heading h1 {
    font-size: 3rem;
    font-weight: bold;
}

.why-content-borderbox {
    width: 66%;
    margin-left: 194px;
}

.box1 {
    height: 60px;

    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.box2 {
    border-top: 1px solid black;
    border-right: 1px solid black;
}


.why-content {
    padding: 20px;

}

.why-content-h h4 {
    text-align: center;
}

.why-content-h h4 {
    color: #8F00FF;
}

.why-content-h {
    height: 50px;
    margin: 20px 0;
}

.why-content-p p {
    text-align: center;
}

.img-block {
    display: block;
    width: 70px;
    height: 60px;
    margin: auto;

}

.img-block img {
    width: 100%;
    height: 100%;
}

.feature-block {
    //margin-top: 50px;
    position: relative;


}

.feature-block-bg {
    background-image: url(../img/section-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.feature-b-heading {
    text-align: center;
    margin: 30px 0 60px;
}

.feature-b-heading h1 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 3rem;
    font-weight: bold;
}



.feature-b-col img {
    display: block;
    margin: auto;
}

.feature-content {
    margin: 20px 0 70px 0;
    font-weight: bold;


}

.f-content-left {
    text-align: right;
}

.f-content-right {
    text-align: left;
}

.f-bubble-left {
    display: block;
    position: absolute;
    top: 170px;
    left: 0;

}



.f-bubble-right {
    display: block;
    position: absolute;
    top: 340px;
    right: 0;

}

.left-padding {
    padding-left: 190px;
}

.right-padding {
    padding-right: 210px;
}

//small screen

@media screen and (max-width: 39.9375em) {

    #login{
        width: 85% !important;
    }

.title-bar{
    background-color: white !important;
}

.top-bar {
    background-color: #a1cdeb !important;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding-right: 0.6rem !important;
    height: auto !important;


}

.title-bar {
    justify-content: space-between !important;
}

.menu-text{
    display: none;
}

.menu-icon::after {
    box-shadow: 0 7px 0 #000000, 0 14px 0 #000000 !important;
    background: #000000 !important;
}

.home-hero h1 {
    color: #8F00FF;
}

.hero-paragraph {

    color:black;
}

.hero-content a:hover {
    
    color: black;
}

    .f-bubble-left {
        display: none;
    }

    .f-bubble-right {
        display: none;
    }

    .left-padding {
        padding-left: 0;
    }

    .right-padding {
        padding-right: 0;
    }

    .f-content-left {
        text-align: center;
    }

    .f-content-right {
        text-align: center;
    }

    .feature-b-col {
        padding: 20px !important;

    }

    .demo-video{
        width: 90% !important;
    }

    .demo-video-content {
        width: 19rem !important;
        padding: 9px 3px !important;
        font-size: 0.9rem;
        line-height: 1rem;

    }


    .video-block {

        height: auto !important;


    }


    .quote-block {

        height: auto !important;


    }

    .quote-mg {
        margin-bottom: 30px;
    }

    .car-wash-content p {

        font-size: 0.5rem !important;
        line-height: 15px !important;

    }

    .anytime-container {
        height: 50vh !important;
    }


    .footer {
        height: auto !important;
        text-align: center;

    }

    .f-about p {
        margin: auto;
    }

    .f-download p {
        margin: auto;
    }



    .car-wash-img {
        width: 100% !important;
        margin: 0 !important;
    }


    .car-wash-content {
        width: 80% !important;
        height: 150px !important;

        position: absolute;
        left: 10% !important;
        top: 120px !important;

    }

    .car-wash-content p {
        text-align: center;
        font-size: 0.9rem !important;
        line-height: 20px !important;

    }

    .app-add {
        height: auto !important;
        padding: 50px !important;
    }


    .app-add p {
        text-align: center;
    }

    .app-add h1 {
        text-align: center;
    }


    .playstore img {
        display: block;
        margin: auto !important;
    }

    .download-app p {
        width: auto !important;
    }

    .download-mobile {
        width: auto !important;
    }

    .playstore {
        position: static !important;
    }

    .download-mobile img {
        margin-top: 30px;
    }

    .why-content-borderbox {
        display: none !important;
    }

    .vl-container {
        display: none !important;
    }

    .quote-border-box {
        display: none !important;
    }

    .hero-content {
    
        text-align: center;
        width: 73%;
    }
    .home-hero {
    background-position: 85%;
    padding-top:0px;
    
    }
    
    .hero-content a {
    margin: auto;
    }
    .form-container-width {
        width: auto !important;
        padding-bottom: 30px;
    }

    .form-content p {
        margin-left: 0 !important;
        width: auto !important;
        height: auto !important;
        padding: 10px 24px !important;
    }

    .form-content p:first-child {
        text-align: center;
    }

}

//medium

@media screen and (min-width: 40em) {
    .quote-block {

        height: auto !important;


    }




}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    #login{
        width: 85% !important;
    }


    .home-hero {
        
        background-position:80% !important; 
    }

    .hero-content {
        margin: 0 0px 0 40px;
    }

    // .f-bubble-left {
    //     display: none;
    // }

    // .f-bubble-right {
    //     display: none;
    // }

    .left-padding {
        padding-left: 0;
    }

    .right-padding {
        padding-right: 0;
    }

    .footer {
        height: auto !important;

    }
    .demo-video {
        width: 80% !important;
    }

    

    .car-wash-img {
        margin: 40px auto 0 !important;
    }

    .car-wash-content {
        width: 65% !important;
        height: 9rem !important;
        position: absolute;
        left: 18% !important;
        top: 180px !important;
    }



    .quote-content {
        width: 11rem !important;
        padding: 15px !important;
    }

    .quote-block {
        padding: 0 !important;
    }

    .quote-mg {
        margin-bottom: 30px;
    }


    .why-content-borderbox {

        margin-left: 129px;
    }

    .quote-border-box{
        margin: 0 0 0 100px !important;
        width: 75% !important;
    }

    .anytime-container {
        height: 50vh !important;
    }

    .form-content p {
        margin-left: 60px !important;
        width: 70% !important;
       
    }
}

/*  medium media query end*/




.video-block {
    background-color: white;
    //height:80vh;
    position: relative;

}

.demo-video-content {
    width: 35rem;
    height: 15rem;
    background-color: white;
    position: absolute;
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 20px 50px;
    border-radius: 30px;
}

div .demo-video-content>* {
    color: black;
    text-align: center;

}

.demo-video {

    width: 55%;
    height: 30rem;
    margin: 103px auto;
    z-index: 0;


}

.demo-video img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
}

.quote-block {

    height: 70vh;
    padding: 0 50px 50px;

}

.quote-border-box {
    height: 60px;
    margin: 0 0 0 180px;
    width: 70%;
}

.vl-container {
    height: 60px;

}

.vl {
    border-right: 1px solid black;
}

.border-1 {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
}


.border-2 {
    border-top: 1px solid black;
    border-right: 1px solid black;
}

.border-3 {
    border-top: 1px solid black;
    border-right: 1px solid black;
}



.quote-content {
    width: 15rem;
    height: 20rem;
    //border-radius: 0 40px 0 40px;
    //background-color: white;
    padding: 30px;
    margin: 0 auto;

}

.q-heading h6 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
}

.q-img {
    margin-bottom: 20px;

}

.q-para p {
    text-align: center;
}



.anytime-container {
    background: url(../img/car-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 110vh;
}

.car-wash {
    position: relative;
    margin-bottom: 70px;

}

.car-wash-heading {
    margin: 50px 0 100px;
    text-align: center;

}


.car-wash-img {
    width: 70%;
    height: auto;
    // background-image: url("../img/foam-wash-2c-car-wash\ 1.png");
    // background-size: contain;
    // background-repeat: no-repeat;
    margin: auto;

}

.car-wash-content {
    width: 60%;
    text-align: center;
    margin: auto;
    font-weight: bold;
}

.car-wash-content p {
    text-align: center;
    font-size: 1rem;
    line-height: 25px;

}

.app-add {
    height: auto;
    background-image: url("../img/section-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    }

.app-add h1 {
    color:black;
}

.app-add p {
    color: black;
}

.download-app{
    text-align: center; 
    
}
.download-app p{
    width: 50%;
    margin: auto;
    text-align: center;
    
}

.playstore{
    position: absolute;
    left: 61%;
    top: 55%;
}


.download-mobile{
    width: 20%;
    margin: 30px auto;
}


.form-container{
    position: relative;
}
.form-heading {
    text-align: center;
    margin: 50px 0;
}

.form-content p{
    margin-left: 170px;
    width: 50%;
    height: 300px;
}


.submit-button {
    background-color: #8F00FF;
    padding: 20px 40px;
    border: none;
    border-radius: 50px !important;
    color: white;
    cursor: pointer;
}

.submit-button:hover{
    background-color: #a95de3;
}


.footer {
    position: relative;
    height: auto;
    background-color: #9106D3;

}

// .f-main-container{
// height: 80vh !important;
// }

.footer-container {
    padding: 50px 0;

}

.form-container-width{
    width: 70%;
}

.footer-container p {
    color: white;
    font-size: 13px;

}

.footer-container a {
    color: white !important;
    font-size: 13px;

}

.footer-container a:hover {
    color: gray;
    font-size: 14px;
}

.footer-b-content {
    text-align: center;

}

.footer-b-content h3 {
    font-size: 26px;
    line-height: 40px;
    color: white;
}


.footer-bottom {


    position: absolute;
    bottom: 0;
}

.footer-bottom-b {
    background-color: #540885;
    height: 30px;

}


.footer h6 {
    color: white;
    font-weight: 600;
    font-size: 16px;
    line-height: 33px;
    margin: 25px 0 20px;
}

.f-about img {
    width: 15% !important;
    margin-bottom: 20px;

}


.f-about p {
    width: 70%;
}

.f-download p {
    width: 70%;
}

.footer-b-content {
    margin-top: 80px;
}

#ovrly{
	background-color: #000;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0px;
	opacity: 0.7;
    z-index: 10000;
}
#login{
	background-color: #fff;
	width: 30%;
	margin: auto;
	border: 3px solid #8F00FF;
	border-radius: 10px;
	padding: 20px;
	text-align: center;
	//position: relative;
	//top: -310px;
    position: absolute;
  top: 50%;
  left: 50%;
  
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  z-index: 11000;
}
#ovrly,#login{display: none;}
#cross{
	position: absolute;
    left: 94%;
    top: 0px;
    color: #8F00FF;
	font-size: 18px;
	font-weight: bold;
	background-color: transparent;
	border: 0;
    cursor: pointer;
}
h1{margin-top: 0px}
form,input[type="button"]{margin:0 !important}

#overlay-paragraph{
    font-size: 0.8rem;
    text-align: left;
    padding-left: 16px;
}

/*contact*/

#formdiv{
    
    background-image: url(../img/map-color.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px 0;
}
.contactform-container{
    display: grid;
    border: none;
    padding: 2rem;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    box-sizing: border-box;
    max-width: 600px;
    height: auto;
    margin:50px auto !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
   

}

.fullwidth{
    grid-column:span 2;
}


